import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { DataPoint, ReportingPeriodDICTItem } from 'src/pages/SignInPage/_BLL/types';
import { RequestStatus } from 'src/shared/api/types';
import { removeDuplicates } from 'src/shared/lib/array';
import { dataPointToColumn } from '../../lib/dataPointToColumn/dataPointToColumn';
import { PeriodFilter, SelectedColumn } from './types';

const NAME = 'customizeColumns';

// * Reducer
export interface State {
	selectedCategory: DataPoint | null;
	selectedColumns: SelectedColumn[];
	activeColumns: SelectedColumn[];
	periodFilter: PeriodFilter;
	columnAutoFit: boolean; // Adding benchmarks directly after relevant dataPoint - If new column option has related option in the list, adds new option after related option.
	status: RequestStatus;
}

export const initialState: State = {
	selectedCategory: null,
	selectedColumns: [], // ! initial columns assigned on login (login slice)
	activeColumns: [], // ! initial columns assigned on login (login slice)
	periodFilter: 'auto',
	columnAutoFit: false,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setInitialColumns: (state, action: PayloadAction<SelectedColumn[]>) => {
			state.selectedColumns = action.payload;
			state.activeColumns = action.payload;
		},
		setCategory: (state, action: { payload: DataPoint | null }) => {
			state.selectedCategory = action.payload ? (state.selectedCategory && state.selectedCategory.id === action.payload.id ? null : action.payload) : null;
		},
		applyColumns: (state, action: { payload: SelectedColumn[] }) => {
			state.activeColumns = action.payload;
		},
		addSelectedColumn: (state, action: { payload: SelectedColumn }) => {
			const newColumn = action.payload;
			const selectedColumns = current(state.selectedColumns);
			const columnsExist = selectedColumns.length > 0;
			const columnsAutoFit = state.columnAutoFit;
			// @ts-ignore
			const lastDisplayNameIndex = selectedColumns.findLastIndex(column => newColumn.displayName.includes(column.displayName));

			const indexes: number[] = [];
			selectedColumns.forEach((column, columnIndex) => {
				column.relatedDatapoints &&
					Object.values(column.relatedDatapoints).forEach(dataPoint => {
						if (dataPoint === newColumn.id) {
							indexes.push(columnIndex);
						}
					});
			});

			const lastMetaIndex = indexes[indexes.length - 1] ?? -1;

			// * New column position in the list.
			if (columnsExist && columnsAutoFit && (lastDisplayNameIndex > -1 || lastMetaIndex > -1)) {
				// Adding benchmarks directly after relevant dataPoint - If new column option has related option in the list, adds new option after related option.
				const newSelectedColumns = [...state.selectedColumns];
				newSelectedColumns.splice((lastMetaIndex > -1 ? lastMetaIndex : lastDisplayNameIndex) + 1, 0, newColumn); // ! Last meta have priority over lastDisplayNameIndex.
				state.selectedColumns = newSelectedColumns;
			} else if (columnsExist) {
				state.selectedColumns = [...state.selectedColumns, newColumn];
			} else {
				state.selectedColumns = [newColumn];
			}
		},
		bulkAddSelectedColumns: (state, action: PayloadAction<SelectedColumn[]>) => {
			state.selectedColumns = [...current(state.selectedColumns), ...action.payload];
		},
		deleteSelectedColumn: (state, action: { payload: SelectedColumn }) => {
			state.selectedColumns = state.selectedColumns.filter(column => column.tableHeaderName !== action.payload.tableHeaderName);
		},
		setSelectedColumnAssociated: (state, action: PayloadAction<{ id: number; associatedDataTakenFromCompany: boolean }>) => {
			const { id, associatedDataTakenFromCompany } = action.payload;
			state.selectedColumns = current(state.selectedColumns).map(column => {
				if (column.id === id) {
					return {
						...column,
						associatedDataTakenFromCompany,
					};
				} else {
					return column;
				}
			});
		},
		setSelectedColumnPeriod: (
			state,
			action: { payload: { columnId: string; period: number; reportingPeriodsDICT: ReportingPeriodDICTItem[]; dataPointsMeta: Record<string, DataPoint> | undefined } },
		) => {
			const { period, columnId, reportingPeriodsDICT, dataPointsMeta } = action.payload;

			const newState =
				state.selectedColumns &&
				state.selectedColumns.map(column => {
					if (column.tableHeaderName === columnId) {
						return dataPointToColumn(
							{
								...column,
								reportingPeriodId: period,
							},
							reportingPeriodsDICT,
							dataPointsMeta,
						);
					} else {
						return { ...column };
					}
				});

			state.selectedColumns = newState && removeDuplicates(newState, 'tableHeaderName');
		},
		setSelectedColumns: (state, action) => {
			state.selectedColumns = action.payload;
		},
		setPeriodFilter: (state, action: { payload: PeriodFilter }) => {
			state.periodFilter = action.payload;
		},
		setColumnsAutoFit: (state, action: PayloadAction<boolean>) => {
			state.columnAutoFit = action.payload;
		},
		clearColumns: (state, action: { payload: { mode: 'selected' | 'active' | 'all' } }) => {
			const { mode } = action.payload;

			if (mode === 'all') {
				return {
					...state,
					activeColumns: [],
					selectedColumns: [],
				};
			}

			if (mode === 'selected') {
				return {
					...state,
					selectedColumns: [],
				};
			}

			if (mode === 'active') {
				return {
					...state,
					activeColumns: [],
				};
			}
		},
		clearAll: state => {
			state.selectedCategory = initialState.selectedCategory;
			state.selectedColumns = initialState.selectedColumns;
			state.activeColumns = initialState.activeColumns;
			state.periodFilter = initialState.periodFilter;
			state.columnAutoFit = initialState.columnAutoFit;
			state.status = initialState.status;
		},
	},
});

export const actionsCustomizeColumns = {
	...slice.actions,
};
