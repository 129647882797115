import { createSlice } from '@reduxjs/toolkit';
import { DataPoint, ReportingPeriodDICTItem } from 'src/pages/SignInPage/_BLL/types';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { createAppAsyncThunk } from '../../../../app/redux/createAction';
import { dataPointToColumn } from '../../lib';
import { CollectionItem, CollectionResItem } from './types';
import { sortByName } from 'src/shared/lib/array';

const NAME = 'collection';

// * Parsing functions
const resToCollection = (res: CollectionResItem[], reportingPeriodsDICT?: ReportingPeriodDICTItem[], dataPointsMeta?: Record<string, DataPoint>): CollectionItem[] => {
	return res.map((collectionItem) => {
		return {
			...collectionItem,
			columns: collectionItem.columns.map((column) => dataPointToColumn(column, reportingPeriodsDICT, dataPointsMeta)),
		};
	});
};

// * Async actions
const getCollection = createAppAsyncThunk(
	`${NAME}/getCollection`,
	async (arg: { reportingPeriods: ReportingPeriodDICTItem[]; dataPoints: Record<string, DataPoint> }, thunkAPI) => {
		const { reportingPeriods, dataPoints } = arg;

		const res = await apiRequest.getRequest<CollectionResItem[]>({
			url: UrlAPI.savedColumns,
			thunkAPI,
		});

		const resWithTableHeaders = resToCollection(res, reportingPeriods, dataPoints);

		return sortByName(resWithTableHeaders) as CollectionItem[];
	},
);

const addCollectionItem = createAppAsyncThunk(`${NAME}/addCollectionItem`, async (arg: { collection: CollectionItem[]; reportingPeriods: ReportingPeriodDICTItem[] }, thunkAPI) => {
	const { collection, reportingPeriods } = arg;

	const payload: CollectionItem[] = [...collection];

	const res = await apiRequest.putRequest<CollectionResItem[]>({
		url: UrlAPI.savedColumns,
		payload,
		thunkAPI,
	});

	const resWithTableHeaders = resToCollection(res, reportingPeriods);
	return sortByName(resWithTableHeaders) as CollectionItem[];
});

const editCollectionItem = createAppAsyncThunk(`${NAME}/editCollectionItem`, async (arg: { payload: CollectionItem[]; reportingPeriods: ReportingPeriodDICTItem[] }, thunkAPI) => {
	const { payload, reportingPeriods } = arg;

	const res: CollectionResItem[] = await apiRequest.putRequest<CollectionResItem[]>({
		url: UrlAPI.savedColumns,
		payload,
		thunkAPI,
	});

	const resWithTableHeaders = resToCollection(res, reportingPeriods);

	return sortByName(resWithTableHeaders) as CollectionItem[];
});

const deleteCollectionItem = createAppAsyncThunk(`${NAME}/deleteColumns`, async (arg: { payload: CollectionItem[]; reportingPeriods: ReportingPeriodDICTItem[] }, thunkAPI) => {
	const { payload, reportingPeriods } = arg;

	const res: CollectionResItem[] = await apiRequest.delRequest<CollectionResItem[]>({
		url: UrlAPI.savedColumns,
		payload,
		thunkAPI,
	});

	const resWithTableHeaders = resToCollection(res, reportingPeriods);
	return sortByName(resWithTableHeaders) as CollectionItem[];
});

// * Reducer
interface State {
	collection: CollectionItem[] | null;
	status: RequestStatus;
}

export const initialState: State = {
	collection: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getCollection.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCollection.fulfilled, (state, action) => {
			const collection = action.payload;

			state.collection = collection.length === 0 ? null : collection;
			state.status = RequestStatus.still;
		});
		builder.addCase(getCollection.rejected, (state) => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(addCollectionItem.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(addCollectionItem.fulfilled, (state, action) => {
			const collection = action.payload;

			state.collection = collection.length === 0 ? null : collection;
			state.status = RequestStatus.still;
		});
		builder.addCase(addCollectionItem.rejected, (state) => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editCollectionItem.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editCollectionItem.fulfilled, (state, action) => {
			const collection = action.payload;

			state.collection = collection.length === 0 ? null : collection;
			state.status = RequestStatus.still;
		});
		builder.addCase(editCollectionItem.rejected, (state) => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(deleteCollectionItem.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(deleteCollectionItem.fulfilled, (state, action) => {
			const collection = action.payload;

			state.collection = collection.length === 0 ? null : collection;
			state.status = RequestStatus.still;
		});
		builder.addCase(deleteCollectionItem.rejected, (state) => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsCollection = {
	...slice.actions,
	getCollection,
	addCollectionItem,
	editCollectionItem,
	deleteCollectionItem,
};
