import { createAppAsyncThunk } from 'src/app/redux/createAction';
import { BoardAndExecDTO, DatasetDTO, GeneralDTO, PeerParams, PerformanceDTO } from './type';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { createSlice } from '@reduxjs/toolkit';

const NAME = 'benchmark';

const getGeneral = createAppAsyncThunk(`${NAME}/getGeneral`, async (arg: { params: Omit<PeerParams, 'id'> }, thunkAPI) => {
	const { params } = arg;
	const { signal } = thunkAPI;

	return await apiRequest.getRequest<GeneralDTO[]>({
		url: `${UrlAPI.peerCompany}/general`,
		params,
		thunkAPI,
		signal,
	});
});

const getPerformance = createAppAsyncThunk(`${NAME}/getPerformance`, async (arg: { params: PeerParams }, thunkAPI) => {
	const { params } = arg;
	const { signal } = thunkAPI;

	return await apiRequest.getRequest<{ [key: string]: PerformanceDTO[] }>({
		url: `${UrlAPI.peerCompany}/performance`,
		params,
		thunkAPI,
		signal,
	});
});

const getBoardAndExec = createAppAsyncThunk(`${NAME}/getBoardAndExec`, async (arg: { params: PeerParams }, thunkAPI) => {
	const { params } = arg;
	const { signal } = thunkAPI;

	return await apiRequest.getRequest<{ [key: string]: BoardAndExecDTO }>({
		url: `${UrlAPI.peerCompany}/boardAndExec`,
		params,
		thunkAPI,
		signal,
	});
});

const getDataset = createAppAsyncThunk(`${NAME}/getDataset`, async (arg: { params: PeerParams }, thunkAPI) => {
	const { params } = arg;
	const { signal } = thunkAPI;

	return await apiRequest.getRequest<{ [key: string]: DatasetDTO }>({
		url: `${UrlAPI.peerCompany}/dataset`,
		params,
		thunkAPI,
		signal,
	});
});

// * Reducer
interface State {
	general: {
		data: GeneralDTO[];
		status: RequestStatus;
	};
	performance: {
		data: { [key: string]: PerformanceDTO[] } | null;
		status: RequestStatus;
	};
	boardAndExec: {
		data: { [key: string]: BoardAndExecDTO } | null;
		status: RequestStatus;
	};
	dataset: {
		data: { [key: string]: DatasetDTO } | null;
		status: RequestStatus;
	};
}

export const initialState: State = {
	general: {
		data: [],
		status: RequestStatus.still,
	},
	performance: {
		data: null,
		status: RequestStatus.still,
	},
	boardAndExec: {
		data: null,
		status: RequestStatus.still,
	},
	dataset: {
		data: null,
		status: RequestStatus.still,
	},
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getGeneral.pending, (state) => {
			state.general.status = RequestStatus.loading;
		});
		builder.addCase(getGeneral.fulfilled, (state, action) => {
			state.general.data = action.payload;
			state.general.status = RequestStatus.still;
		});
		builder.addCase(getGeneral.rejected, (state) => {
			state.general.status = RequestStatus.failed;
		});

		builder.addCase(getPerformance.pending, (state) => {
			state.performance.status = RequestStatus.loading;
		});
		builder.addCase(getPerformance.fulfilled, (state, action) => {
			state.performance.data = action.payload;
			state.performance.status = RequestStatus.still;
		});
		builder.addCase(getPerformance.rejected, (state) => {
			state.performance.status = RequestStatus.failed;
		});

		builder.addCase(getBoardAndExec.pending, (state) => {
			state.boardAndExec.status = RequestStatus.loading;
		});
		builder.addCase(getBoardAndExec.fulfilled, (state, action) => {
			state.boardAndExec.data = action.payload;
			state.boardAndExec.status = RequestStatus.still;
		});
		builder.addCase(getBoardAndExec.rejected, (state) => {
			state.boardAndExec.status = RequestStatus.failed;
		});

		builder.addCase(getDataset.pending, (state) => {
			state.dataset.status = RequestStatus.loading;
		});
		builder.addCase(getDataset.fulfilled, (state, action) => {
			state.dataset.data = action.payload;
			state.dataset.status = RequestStatus.still;
		});
		builder.addCase(getDataset.rejected, (state) => {
			state.dataset.status = RequestStatus.failed;
		});
	},
});

export const actionsBenchmark = {
	...slice.actions,
	getGeneral,
	getPerformance,
	getBoardAndExec,
	getDataset,
};
