import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addDataPoints } from 'src/app/indexedDB/dataPointsDB/functions/addDataPoints';
import { clearDataPoints } from 'src/app/indexedDB/dataPointsDB/functions/clearDataPoints';
import { addDataPointTree } from 'src/app/indexedDB/dataPointsTreeDB/functions/addDataPointTree';
import { clearDataPointTree } from 'src/app/indexedDB/dataPointsTreeDB/functions/clearDataPointTree';
import { addDictionaries } from 'src/app/indexedDB/dictionaryDB/functions/addDictionaries';
import { clearDictionaries } from 'src/app/indexedDB/dictionaryDB/functions/clearDictionaries';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { ColumnsMeta, Dictionaries, ForgotPasswordREQ, ForgotPasswordRES, ResetPasswordREQ, ResetPasswordRES, LoginValues, ThemeName, UserData, LoginMFAValues } from './types';
import { isAccessDenied } from 'src/shared/types/_guards/server_errors/isAccessDenied';
import { actionsCustomizeColumns } from 'src/pages/MainSearchResultsPage/api/customizeColumns/slice';
import { SelectedColumn } from 'src/pages/MainSearchResultsPage/api/customizeColumns/types';

const NAME = UrlAPI.login;

// * Shared
const handleCore = createAsyncThunk(`${NAME}/login`, async (token: string, thunkAPI) => {
	const { dispatch } = thunkAPI;

	const dictionaries = await apiRequest.getRequest<Dictionaries>({
		url: UrlAPI.dictionaries,
		thunkAPI,
		token,
	});

	const columnsMeta = await apiRequest.getRequest<ColumnsMeta>({
		url: UrlAPI.columnsMetadata,
		thunkAPI,
		token,
	});

	await Promise.allSettled([dictionaries, columnsMeta]);

	// Assign initial columns to search results page
	const initialColumnsIds = [1000014, 1000016, 1000017, 1000019, 1000204, 10400001, 10401001, 10406001, 10405001];
	const initialColumns: SelectedColumn[] = [];

	for (const dataPointId of initialColumnsIds) {
		const column = columnsMeta.dataPoints[dataPointId];
		initialColumns.push({
			...column,
			associatedDataTakenFromCompany: false,
			tableHeaderName: column.displayName,
		});
	}

	await dispatch(actionsCustomizeColumns.setInitialColumns(initialColumns));

	// Assign to local DB
	const dictionariesDB = clearDictionaries().then(() => addDictionaries(dictionaries));

	const columnsMetaDB1 = clearDataPoints().then(() => addDataPoints(columnsMeta.dataPoints));
	const columnsMetaDB2 = clearDataPointTree().then(() => addDataPointTree(columnsMeta.dataPointTree));

	await Promise.allSettled([dictionariesDB, columnsMetaDB1, columnsMetaDB2]);
});

// * API requests
const login = createAsyncThunk(`${NAME}/login`, async (payload: LoginValues, thunkAPI) => {
	const { dispatch } = thunkAPI;

	const res = await apiRequest.postRequest<UserData>({
		url: NAME,
		payload,
		thunkAPI,
	});

	await dispatch(handleCore(res.token));

	return res;
});

const loginMFA = createAsyncThunk(`${NAME}/loginMFA`, async (payload: LoginMFAValues, thunkAPI) => {
	const { dispatch } = thunkAPI;

	const res = await apiRequest.postRequest<UserData>({
		url: `${NAME}/mfa`,
		payload,
		thunkAPI,
	});

	await dispatch(handleCore(res.token));

	return res;
});

const forgotPassword = createAsyncThunk(`${NAME}/forgotPassword`, async (arg: ForgotPasswordREQ, thunkAPI) => {
	const { params } = arg;

	localStorage.setItem('temp_user_name', params.user_name);

	return await apiRequest.postRequest<ForgotPasswordRES>({
		url: `${NAME}/password`,
		params,
		thunkAPI,
	});
});

const resetPassword = createAsyncThunk(`${NAME}/resetPassword`, async (arg: ResetPasswordREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.putRequest<ResetPasswordRES>({
		url: `${NAME}/password`,
		params,
		thunkAPI,
	});
});

// * Reducer
export interface State {
	activeTheme: ThemeName;
	userData: UserData | null;
	resetStatus: string | null;
	mfa: boolean;
	status: RequestStatus;
}

export const initialState: State = {
	activeTheme: 'default',
	userData: null,
	resetStatus: null,
	mfa: false,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		logout: state => {
			state.userData = null;
		},
		storeUserData: (state, action: { payload: UserData }) => {
			state.userData = action.payload;
		},
		setTheme: (state, action: PayloadAction<'default' | 'bw'>) => {
			state.activeTheme = action.payload;
		},
		clearResetStatus: state => {
			state.resetStatus = null;
		},
		setMFA: (state, action: PayloadAction<boolean>) => {
			state.mfa = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(login.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(login.fulfilled, (state, action) => {
			state.userData = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(login.rejected, (state, action) => {
			const error = action.payload;

			if (isAccessDenied(error) && error.message.startsWith('Multi-Factor Authentication required')) {
				state.mfa = true;
			}

			state.status = RequestStatus.failed;
		});

		builder.addCase(loginMFA.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(loginMFA.fulfilled, (state, action) => {
			state.userData = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(loginMFA.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(forgotPassword.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(forgotPassword.fulfilled, (state, action) => {
			state.status = RequestStatus.still;
			state.resetStatus = action.payload.status;
		});
		builder.addCase(forgotPassword.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(resetPassword.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(resetPassword.fulfilled, (state, action) => {
			state.status = RequestStatus.still;
			state.resetStatus = action.payload.status;
		});
		builder.addCase(resetPassword.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsAuth = {
	...slice.actions,
	login,
	loginMFA,
	forgotPassword,
	resetPassword,
};
