import { DataPoint, ReportingPeriodDICTItem } from 'src/pages/SignInPage/_BLL/types';
import { SelectedColumn } from '../../api/customizeColumns/types';
import { periodNullToValue } from '../periodNullToValue/periodNullToValue';

export const getPeriodName = (periodId: string | number | null, reportingPeriodsDICT: ReportingPeriodDICTItem[]) => {
	if (periodId === null) {
		return '';
	} else if (+periodId === reportingPeriodsDICT[0].id) {
		return '';
	} else {
		return ` (${reportingPeriodsDICT.find(period => period.id === +periodId)?.name})`;
	}
};

const createColumnNameWithPeriod = (name: string, periodId: string | number | null, reportingPeriodsDICT: ReportingPeriodDICTItem[]) => {
	return `${name}${getPeriodName(periodId, reportingPeriodsDICT)}`;
};

// Takes dataPoint and converts it to column with tableHeaderName and converts null to number in reporting reportingPeriodId.
export const dataPointToColumn = (
	dataPoint: DataPoint & { associatedDataTakenFromCompany?: boolean },
	reportingPeriodsDICT: ReportingPeriodDICTItem[] | undefined,
	dataPointsMeta: Record<string, DataPoint> | undefined,
): SelectedColumn => {
	const datapointMeta = dataPointsMeta && Object.entries(dataPointsMeta)?.find(([key]) => Number(key) === dataPoint.id)?.[1];

	const dataPointBase = datapointMeta || dataPoint;

	return {
		...dataPointBase,
		tableHeaderName: reportingPeriodsDICT ? createColumnNameWithPeriod(dataPoint.displayName, dataPoint.reportingPeriodId, reportingPeriodsDICT) : dataPoint.displayName,
		reportingPeriodId: periodNullToValue(dataPoint.reportingPeriodId, reportingPeriodsDICT),
		relatedDatapoints: datapointMeta?.relatedDatapoints ?? null,
		isAssociated: datapointMeta?.isAssociated ?? false,
		associatedDataTakenFromCompany: dataPoint?.associatedDataTakenFromCompany ?? false, // Initial value
	};
};
