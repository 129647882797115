import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';

export const theme: Theme = createTheme({
	typography: {
		fontFamily: 'Poppins, sans-serif',
	},
	palette: {
		primary: {
			main: '#00356B',
		},
		secondary: {
			main: '#ffffff',
		},
		rating: {
			healthy: {
				aaa: '#A3E5B2',
				aa: '#A3E5B2',
				a: '#A3E5B2',
				bgc: '#e8ffe8',
			},
			balanced: {
				bbb: '#FFEAA0',
				bb: '#FFEAA0',
				b: '#FFEAA0',
				bgc: '#fffbef',
			},
			poor: {
				ccc: '#FFD2D2',
				cc: '#FFD2D2',
				c: '#FFD2D2',
				bgc: '#fff1f1',
			},
			problematic: {
				d: '#FFD2D2',
				bgc: '#fff1f1',
			},
		},
		gender: {
			male: '#ffbe80',
			female: '#A3E5B2',
			other: '#7f9dff',
		},
		race: {
			middleEastern: '#A3E5B2',
			hispanic: '#de7a7a',
			black: '#FFC165',
			asian: '#c982f6',
			caucasian: '#A4C0FF',
			indigenous: '#ab8169',
		},
		custom: {
			appBackground: '#f4f6f8',
			light: '#D0DEF5',
			mainBorder: '#ABBDD9',
			mainPale: '#E5EDF5',
			mainLight: '#57aae2',
			mainDark: '#c6d1db',
			barIndicator: {
				primary: '#2B69F0',
				secondary: '#A4C0FF',
			},
		},
		status: {
			good: '#c0ffd3',
			bad: '#FFD9D9',
			empty: '#E5E8F2',
		},
	},
	shadows: [
		'none',
		'0px 0px 20px 1px rgba(0,0,0,0.1)',
		'0px 0px 20px 2px rgba(0,0,0,0.2)',
		'0px 0px 20px 3px rgba(0,0,0,0.2)',
		'0px 0px 20px 4px rgba(0,0,0,0.2)',
		'0px 0px 20px 5px rgba(0,0,0,0.2)',
		'0px 0px 20px 6px rgba(0,0,0,0.2)',
		'0px 0px 20px 7px rgba(0,0,0,0.2)',
		'0px 0px 20px 8px rgba(0,0,0,0.2)',
		'0px 0px 20px 9px rgba(0,0,0,0.2)',
		'0px 0px 20px 10px rgba(0,0,0,0.2)',
		'0px 0px 20px 11px rgba(0,0,0,0.2)',
		'0px 0px 20px 12px rgba(0,0,0,0.2)',
		'0px 0px 20px 13px rgba(0,0,0,0.2)',
		'0px 0px 20px 14px rgba(0,0,0,0.2)',
		'0px 0px 20px 15px rgba(0,0,0,0.2)',
		'0px 0px 20px 16px rgba(0,0,0,0.2)',
		'0px 0px 20px 17px rgba(0,0,0,0.2)',
		'0px 0px 20px 18px rgba(0,0,0,0.2)',
		'0px 0px 20px 19px rgba(0,0,0,0.2)',
		'0px 0px 20px 20px rgba(0,0,0,0.2)',
		'0px 0px 20px 21px rgba(0,0,0,0.2)',
		'0px 0px 20px 22px rgba(0,0,0,0.2)',
		'0px 0px 20px 23px rgba(0,0,0,0.2)',
		'0px 0px 20px 24px rgba(0,0,0,0.2)',
	],
	// overrides: {
	// 	MuiTooltip: {
	// 		tooltip: {
	// 			fontSize: '.8rem',
	// 			padding: '0.7rem 1rem',
	// 			backgroundColor: '#efefef',
	// 			color: '#212121',
	// 		},
	// 	},
	// },
});
